import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var operationApiCalls = {
  loadOperationById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_OPERATIONBYID_REQUEST, ActionTypes.GET_OPERATIONBYID_SUCCESS, ActionTypes.GET_OPERATIONBYID_FAILURE, ActionTypes.RESET_GET_OPERATIONBYID]
  }),
  loadOperationRawJsonById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_OPERATIONRAWJSONBYID_REQUEST, ActionTypes.GET_OPERATIONRAWJSONBYID_SUCCESS, ActionTypes.GET_OPERATIONRAWJSONBYID_FAILURE, ActionTypes.RESET_GET_OPERATIONRAWJSONBYID]
  }),
  generateOpeningBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GENERATEOPENINGBALANCES_REQUEST, ActionTypes.GENERATEOPENINGBALANCES_SUCCESS, ActionTypes.GENERATEOPENINGBALANCES_FAILURE, ActionTypes.RESET_GENERATEOPENINGBALANCES]
  }),
  abortGenerateOpeningBalances: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTGENERATEOPENINGBALANCES_REQUEST, ActionTypes.ABORTGENERATEOPENINGBALANCES_SUCCESS, ActionTypes.ABORTGENERATEOPENINGBALANCES_FAILURE, ActionTypes.RESET_ABORTGENERATEOPENINGBALANCES]
  }),
  findRebalanceOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.FINDREBALANCEOPERATIONS_REQUEST, ActionTypes.FINDREBALANCEOPERATIONS_SUCCESS, ActionTypes.FINDREBALANCEOPERATIONS_FAILURE, ActionTypes.RESET_FINDREBALANCEOPERATIONS]
  }),
  abortFindRebalanceOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTFINDREBALANCEOPERATIONS_REQUEST, ActionTypes.ABORTFINDREBALANCEOPERATIONS_SUCCESS, ActionTypes.ABORTFINDREBALANCEOPERATIONS_FAILURE, ActionTypes.RESET_ABORTFINDREBALANCEOPERATIONS]
  }),
  findSplitOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.FINDSPLITOPERATIONS_REQUEST, ActionTypes.FINDSPLITOPERATIONS_SUCCESS, ActionTypes.FINDSPLITOPERATIONS_FAILURE, ActionTypes.RESET_FINDSPLITOPERATIONS]
  }),
  abortFindSplitOperations: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.ABORTFINDSPLITOPERATIONS_REQUEST, ActionTypes.ABORTFINDSPLITOPERATIONS_SUCCESS, ActionTypes.ABORTFINDSPLITOPERATIONS_FAILURE, ActionTypes.RESET_ABORTFINDSPLITOPERATIONS]
  }),
  postOperations: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.form;
    },
    types: [ActionTypes.POST_OPERATIONS_REQUEST, ActionTypes.POST_OPERATIONS_SUCCESS, ActionTypes.POST_OPERATIONS_FAILURE, ActionTypes.RESET_POST_OPERATIONS]
  }),
  postOperation: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.form, action.operation.id].join("-");
    },
    types: [ActionTypes.POST_OPERATION_REQUEST, ActionTypes.POST_OPERATION_SUCCESS, ActionTypes.POST_OPERATION_FAILURE, ActionTypes.RESET_POST_OPERATION]
  }),
  deleteOperation: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.operation.id + "";
    },
    types: [ActionTypes.DELETE_OPERATION_REQUEST, ActionTypes.DELETE_OPERATION_SUCCESS, ActionTypes.DELETE_OPERATION_FAILURE, ActionTypes.RESET_DELETE_OPERATION]
  }),
  loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN]
  }),
  loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN]
  }),
  loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN]
  }),
  loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.states, action.requestedCurrencyAmount].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN]
  }),
  fetchDepositsByUserIdAndState: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.state].join("-");
    },
    types: [ActionTypes.GET_DEPOSITSBYUSERIDANDSTATE_REQUEST, ActionTypes.GET_DEPOSITSBYUSERIDANDSTATE_SUCCESS, ActionTypes.GET_DEPOSITSBYUSERIDANDSTATE_FAILURE, ActionTypes.RESET_GET_DEPOSITSBYUSERIDANDSTATE]
  }),
  loadOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.states].join("-");
    },
    types: [ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_REQUEST, ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS, ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_FAILURE, ActionTypes.RESET_GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT]
  })
};
export default operationApiCalls;