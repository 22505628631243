import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemMentionListItemApiCalls = {
  fetchInvestmentItemMentionListItemsByInvestmentItemIdAndAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentItemId, action.authUserId].join("-");
    },
    types: [ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID_REQUEST, ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID_SUCCESS, ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID]
  })
};
export default investmentItemMentionListItemApiCalls;