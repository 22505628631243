function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_OPERATIONBYID_REQUEST = "GET_OPERATIONBYID_REQUEST";
export var GET_OPERATIONBYID_SUCCESS = "GET_OPERATIONBYID_SUCCESS";
export var GET_OPERATIONBYID_FAILURE = "GET_OPERATIONBYID_FAILURE";
export var RESET_GET_OPERATIONBYID = "RESET_GET_OPERATIONBYID";

// Fetches a particular operation from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchOperationById = function fetchOperationById(id) {
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_OPERATIONBYID_REQUEST, GET_OPERATIONBYID_SUCCESS, GET_OPERATIONBYID_FAILURE],
    endpoint: "operations/".concat(id),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular operation by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadOperationById = function loadOperationById(id, forceUpdate) {
  return function (dispatch, getState) {
    var operation = getState().entities.operations.byId[id];
    if (operation && !forceUpdate) return null;
    return dispatch(fetchOperationById(id));
  };
};
export var GET_OPERATIONRAWJSONBYID_REQUEST = "GET_OPERATIONRAWJSONBYID_REQUEST";
export var GET_OPERATIONRAWJSONBYID_SUCCESS = "GET_OPERATIONRAWJSONBYID_SUCCESS";
export var GET_OPERATIONRAWJSONBYID_FAILURE = "GET_OPERATIONRAWJSONBYID_FAILURE";
export var RESET_GET_OPERATIONRAWJSONBYID = "RESET_GET_OPERATIONRAWJSONBYID";

// Fetches a particular operation's rawJson from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var loadOperationRawJsonById = function loadOperationRawJsonById(id) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id
    }, CALL_API, {
      types: [GET_OPERATIONRAWJSONBYID_REQUEST, GET_OPERATIONRAWJSONBYID_SUCCESS, GET_OPERATIONRAWJSONBYID_FAILURE],
      endpoint: "operations/".concat(id, "/rawJson"),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONS_REQUEST = "GET_OPERATIONS_REQUEST";
export var GET_OPERATIONS_SUCCESS = "GET_OPERATIONS_SUCCESS";
export var GET_OPERATIONS_FAILURE = "GET_OPERATIONS_FAILURE";
export var loadOperations = function loadOperations(page, size, statesFilterValue, idFilterValue, boostIdFilterValue, brokerIdFilterValue, currencyIdFilterValue, goalIdFilterValue, investmentItemTypeFilterValue, investmentItemIdFilterValue, userIdFilterValue, userInvestmentAccountNotEmptyFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue, typeFilterValue, transactionTypeFilterValue, cashFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "id,desc",
      projection: "full",
      statesFilterValue: statesFilterValue,
      idFilterValue: idFilterValue,
      boostIdFilterValue: boostIdFilterValue,
      brokerIdFilterValue: brokerIdFilterValue,
      currencyIdFilterValue: currencyIdFilterValue,
      goalIdFilterValue: goalIdFilterValue,
      investmentItemTypeFilterValue: investmentItemTypeFilterValue,
      investmentItemIdFilterValue: investmentItemIdFilterValue,
      userIdFilterValue: userIdFilterValue,
      userInvestmentAccountNotEmptyFilterValue: userInvestmentAccountNotEmptyFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue,
      typeFilterValue: typeFilterValue,
      transactionTypeFilterValue: transactionTypeFilterValue,
      cashFilterValue: cashFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_OPERATIONS_REQUEST, GET_OPERATIONS_SUCCESS, GET_OPERATIONS_FAILURE],
      endpoint: "operations/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYBROKERID_REQUEST = "GET_OPERATIONSBYBROKERID_REQUEST";
export var GET_OPERATIONSBYBROKERID_SUCCESS = "GET_OPERATIONSBYBROKERID_SUCCESS";
export var GET_OPERATIONSBYBROKERID_FAILURE = "GET_OPERATIONSBYBROKERID_FAILURE";
export var loadOperationsByBrokerId = function loadOperationsByBrokerId(page, size, brokerId, idFilterValue, stateFilterValue, currencyIdFilterValue, investmentItemTypeFilterValue, investmentItemIdFilterValue, userIdFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      projection: "withBoostAndCurrencyAndGoalAndInvestmentItem",
      brokerId: brokerId,
      idFilterValue: idFilterValue,
      stateFilterValue: stateFilterValue,
      currencyIdFilterValue: currencyIdFilterValue,
      investmentItemTypeFilterValue: investmentItemTypeFilterValue,
      investmentItemIdFilterValue: investmentItemIdFilterValue,
      userIdFilterValue: userIdFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      brokerId: brokerId
    }, CALL_API, {
      types: [GET_OPERATIONSBYBROKERID_REQUEST, GET_OPERATIONSBYBROKERID_SUCCESS, GET_OPERATIONSBYBROKERID_FAILURE],
      endpoint: "operations/search/findAllByBroker_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};

// export const GET_OPERATION_REQUEST = 'GET_OPERATION_REQUEST'
// export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS'
// export const GET_OPERATION_FAILURE = 'GET_OPERATION_FAILURE'
// export const RESET_GET_OPERATION = 'RESET_GET_OPERATION'

// export const fetchOperationById = id => (dispatch, getState) => dispatch({
//   id,
//   [CALL_API]: {
//     types: [ GET_OPERATION_REQUEST,
//       GET_OPERATION_SUCCESS,
//       GET_OPERATION_FAILURE ],
//     endpoint: `operations/fetchById?id=${id}`,
//     init: { credentials: 'same-origin' }
//   }
// })

export var GENERATEOPENINGBALANCES_REQUEST = "GENERATEOPENINGBALANCES_REQUEST";
export var GENERATEOPENINGBALANCES_SUCCESS = "GENERATEOPENINGBALANCES_SUCCESS";
export var GENERATEOPENINGBALANCES_FAILURE = "GENERATEOPENINGBALANCES_FAILURE";
export var RESET_GENERATEOPENINGBALANCES = "RESET_GENERATEOPENINGBALANCES";
export var generateOpeningBalances = function generateOpeningBalances(fromCommittedAt, toCommittedAt, correctMismatch) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GENERATEOPENINGBALANCES_REQUEST, GENERATEOPENINGBALANCES_SUCCESS, GENERATEOPENINGBALANCES_FAILURE],
      endpoint: "operations/generateOpeningBalances?fromCommittedAt=".concat(fromCommittedAt, "&toCommittedAt=").concat(toCommittedAt, "&correctMismatch=").concat(correctMismatch),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTGENERATEOPENINGBALANCES_REQUEST = "ABORTGENERATEOPENINGBALANCES_REQUEST";
export var ABORTGENERATEOPENINGBALANCES_SUCCESS = "ABORTGENERATEOPENINGBALANCES_SUCCESS";
export var ABORTGENERATEOPENINGBALANCES_FAILURE = "ABORTGENERATEOPENINGBALANCES_FAILURE";
export var RESET_ABORTGENERATEOPENINGBALANCES = "RESET_ABORTGENERATEOPENINGBALANCES";
export var abortGenerateOpeningBalances = function abortGenerateOpeningBalances() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTGENERATEOPENINGBALANCES_REQUEST, ABORTGENERATEOPENINGBALANCES_SUCCESS, ABORTGENERATEOPENINGBALANCES_FAILURE],
      endpoint: "operations/abortGenerateOpeningBalances",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GENERATEOPENINGBALANCESCONTEXT_REQUEST = "GET_GENERATEOPENINGBALANCESCONTEXT_REQUEST";
export var GET_GENERATEOPENINGBALANCESCONTEXT_SUCCESS = "GET_GENERATEOPENINGBALANCESCONTEXT_SUCCESS";
export var GET_GENERATEOPENINGBALANCESCONTEXT_FAILURE = "GET_GENERATEOPENINGBALANCESCONTEXT_FAILURE";
export var RESET_GET_GENERATEOPENINGBALANCESCONTEXT = "RESET_GET_GENERATEOPENINGBALANCESCONTEXT";
export var loadGenerateOpeningBalancesContext = function loadGenerateOpeningBalancesContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GENERATEOPENINGBALANCESCONTEXT_REQUEST, GET_GENERATEOPENINGBALANCESCONTEXT_SUCCESS, GET_GENERATEOPENINGBALANCESCONTEXT_FAILURE],
      endpoint: "operations/generateOpeningBalancesContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var FINDREBALANCEOPERATIONS_REQUEST = "FINDREBALANCEOPERATIONS_REQUEST";
export var FINDREBALANCEOPERATIONS_SUCCESS = "FINDREBALANCEOPERATIONS_SUCCESS";
export var FINDREBALANCEOPERATIONS_FAILURE = "FINDREBALANCEOPERATIONS_FAILURE";
export var RESET_FINDREBALANCEOPERATIONS = "RESET_FINDREBALANCEOPERATIONS ";
export var findRebalanceOperations = function findRebalanceOperations(committedById, brokerIds, date) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [FINDREBALANCEOPERATIONS_REQUEST, FINDREBALANCEOPERATIONS_SUCCESS, FINDREBALANCEOPERATIONS_FAILURE],
      endpoint: "operations/findRebalance?committedById=".concat(committedById, "&brokerIds=").concat(brokerIds, "&date=").concat(date),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTFINDREBALANCEOPERATIONS_REQUEST = "ABORTFINDREBALANCEOPERATIONS_REQUEST";
export var ABORTFINDREBALANCEOPERATIONS_SUCCESS = "ABORTFINDREBALANCEOPERATIONS_SUCCESS";
export var ABORTFINDREBALANCEOPERATIONS_FAILURE = "ABORTFINDREBALANCEOPERATIONS_FAILURE";
export var RESET_ABORTFINDREBALANCEOPERATIONS = "RESET_ABORTFINDREBALANCEOPERATIONS";
export var abortFindRebalanceOperations = function abortFindRebalanceOperations() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTFINDREBALANCEOPERATIONS_REQUEST, ABORTFINDREBALANCEOPERATIONS_SUCCESS, ABORTFINDREBALANCEOPERATIONS_FAILURE],
      endpoint: "operations/abortFindRebalance",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_FINDREBALANCEOPERATIONSCONTEXT_REQUEST = "GET_FINDREBALANCEOPERATIONSCONTEXT_REQUEST";
export var GET_FINDREBALANCEOPERATIONSCONTEXT_SUCCESS = "GET_FINDREBALANCEOPERATIONSCONTEXT_SUCCESS";
export var GET_FINDREBALANCEOPERATIONSCONTEXT_FAILURE = "GET_FINDREBALANCEOPERATIONSCONTEXT_FAILURE";
export var RESET_GET_FINDREBALANCEOPERATIONSCONTEXT = "RESET_GET_FINDREBALANCEOPERATIONSCONTEXT";
export var loadFindRebalanceOperationsContext = function loadFindRebalanceOperationsContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_FINDREBALANCEOPERATIONSCONTEXT_REQUEST, GET_FINDREBALANCEOPERATIONSCONTEXT_SUCCESS, GET_FINDREBALANCEOPERATIONSCONTEXT_FAILURE],
      endpoint: "operations/findRebalanceContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var FINDSPLITOPERATIONS_REQUEST = "FINDSPLITOPERATIONS_REQUEST";
export var FINDSPLITOPERATIONS_SUCCESS = "FINDSPLITOPERATIONS_SUCCESS";
export var FINDSPLITOPERATIONS_FAILURE = "FINDSPLITOPERATIONS_FAILURE";
export var RESET_FINDSPLITOPERATIONS = "RESET_FINDSPLITOPERATIONS ";
export var findSplitOperations = function findSplitOperations(investmentItemId, splitFactor, splitDate, committedById) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [FINDSPLITOPERATIONS_REQUEST, FINDSPLITOPERATIONS_SUCCESS, FINDSPLITOPERATIONS_FAILURE],
      endpoint: "operations/findSplit?investmentItemId=".concat(investmentItemId, "&splitFactor=").concat(splitFactor, "&splitDate=").concat(splitDate, "&committedById=").concat(committedById),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTFINDSPLITOPERATIONS_REQUEST = "ABORTFINDSPLITOPERATIONS_REQUEST";
export var ABORTFINDSPLITOPERATIONS_SUCCESS = "ABORTFINDSPLITOPERATIONS_SUCCESS";
export var ABORTFINDSPLITOPERATIONS_FAILURE = "ABORTFINDSPLITOPERATIONS_FAILURE";
export var RESET_ABORTFINDSPLITOPERATIONS = "RESET_ABORTFINDSPLITOPERATIONS";
export var abortFindSplitOperations = function abortFindSplitOperations() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTFINDSPLITOPERATIONS_REQUEST, ABORTFINDSPLITOPERATIONS_SUCCESS, ABORTFINDSPLITOPERATIONS_FAILURE],
      endpoint: "operations/abortFindSplit",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_FINDSPLITOPERATIONSCONTEXT_REQUEST = "GET_FINDSPLITOPERATIONSCONTEXT_REQUEST";
export var GET_FINDSPLITOPERATIONSCONTEXT_SUCCESS = "GET_FINDSPLITOPERATIONSCONTEXT_SUCCESS";
export var GET_FINDSPLITOPERATIONSCONTEXT_FAILURE = "GET_FINDSPLITOPERATIONSCONTEXT_FAILURE";
export var RESET_GET_FINDSPLITOPERATIONSCONTEXT = "RESET_GET_FINDSPLITOPERATIONSCONTEXT";
export var loadFindSplitOperationsContext = function loadFindSplitOperationsContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_FINDSPLITOPERATIONSCONTEXT_REQUEST, GET_FINDSPLITOPERATIONSCONTEXT_SUCCESS, GET_FINDSPLITOPERATIONSCONTEXT_FAILURE],
      endpoint: "operations/findSplitContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var DELETE_OPERATION_REQUEST = "DELETE_OPERATION_REQUEST";
export var DELETE_OPERATION_SUCCESS = "DELETE_OPERATION_SUCCESS";
export var DELETE_OPERATION_FAILURE = "DELETE_OPERATION_FAILURE";
export var RESET_DELETE_OPERATION = "RESET_DELETE_OPERATION";
export var deleteOperation = function deleteOperation(operation) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      operation: operation
    }, CALL_API, {
      types: [DELETE_OPERATION_REQUEST, DELETE_OPERATION_SUCCESS, DELETE_OPERATION_FAILURE],
      endpoint: "operations/".concat(operation.id),
      init: {
        method: "DELETE",
        credentials: "same-origin"
      }
    }));
  };
};
export var resetDeleteOperation = function resetDeleteOperation(operation) {
  return function (dispatch, getState) {
    return dispatch({
      operation: operation,
      type: RESET_DELETE_OPERATION
    });
  };
};
export var POST_OPERATIONS_REQUEST = "POST_OPERATIONS_REQUEST";
export var POST_OPERATIONS_SUCCESS = "POST_OPERATIONS_SUCCESS";
export var POST_OPERATIONS_FAILURE = "POST_OPERATIONS_FAILURE";
export var RESET_POST_OPERATIONS = "RESET_POST_OPERATIONS";
export var postOperations = function postOperations(form, operations) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      form: form,
      operations: operations,
      userId: operations[0].userId
    }, CALL_API, {
      types: [POST_OPERATIONS_REQUEST, POST_OPERATIONS_SUCCESS, POST_OPERATIONS_FAILURE],
      endpoint: "operations",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(operations),
        credentials: "same-origin"
      }
    }));
  };
};
export var postOperationsFailure = function postOperationsFailure(form, message) {
  return function (dispatch, getState) {
    return dispatch({
      form: form,
      response: {
        message: message
      },
      type: POST_OPERATIONS_FAILURE
    });
  };
};
export var resetPostOperations = function resetPostOperations(form) {
  return function (dispatch, getState) {
    return dispatch({
      form: form,
      type: RESET_POST_OPERATIONS
    });
  };
};
export var POST_OPERATION_REQUEST = "POST_OPERATION_REQUEST";
export var POST_OPERATION_SUCCESS = "POST_OPERATION_SUCCESS";
export var POST_OPERATION_FAILURE = "POST_OPERATION_FAILURE";
export var RESET_POST_OPERATION = "RESET_POST_OPERATION";
export var postOperation = function postOperation(form, operation) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      form: form,
      operation: operation
    }, CALL_API, {
      types: [POST_OPERATION_REQUEST, POST_OPERATION_SUCCESS, POST_OPERATION_FAILURE],
      endpoint: "operations/".concat(operation.id),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(operation),
        credentials: "same-origin"
      }
    }));
  };
};
export var postOperationFailure = function postOperationFailure(form, message) {
  return function (dispatch, getState) {
    return dispatch({
      form: form,
      response: {
        message: message
      },
      type: POST_OPERATION_FAILURE
    });
  };
};
export var resetPostOperation = function resetPostOperation(form, operation) {
  return function (dispatch, getState) {
    return dispatch({
      form: form,
      operation: operation,
      type: RESET_POST_OPERATION
    });
  };
};
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST";
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS";
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE";
export var RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN = "RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN";
export var loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = function loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan(goalId, userId, states, requestedCurrencyAmount) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    });
    return dispatch(_defineProperty({
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    }, CALL_API, {
      types: [GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE],
      endpoint: "operations/search/findByGoal_IdAndUser_IdAndStateInAndRequestedCurrencyAmountGreaterThan".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST";
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS";
export var GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE = "GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE";
export var RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN = "RESET_GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN";
export var loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan = function loadOperationsByGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan(goalId, userId, states, requestedCurrencyAmount) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    });
    return dispatch(_defineProperty({
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    }, CALL_API, {
      types: [GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE],
      endpoint: "operations/search/findByGoal_IdAndUser_IdAndStateInAndRequestedCurrencyAmountLessThan".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST";
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS";
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE";
export var RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN = "RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN";
export var loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan = function loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateInAndRequestedCurrencyAmountGreaterThan(brokerId, currencyId, goalId, userId, states, requestedCurrencyAmount) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    });
    return dispatch(_defineProperty({
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    }, CALL_API, {
      types: [GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_REQUEST, GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS, GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_FAILURE],
      endpoint: "operations/search/findByBroker_IdAndCurrency_IdAndGoal_IdAndUser_IdAndStateInAndRequestedCurrencyAmountGreaterThan".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST";
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS";
export var GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE = "GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE";
export var RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN = "RESET_GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN";
export var loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan = function loadOperationsByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateInAndRequestedCurrencyAmountLessThan(brokerId, currencyId, goalId, investmentItemId, userId, states, requestedCurrencyAmount) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      investmentItemId: investmentItemId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    });
    return dispatch(_defineProperty({
      brokerId: brokerId,
      currencyId: currencyId,
      goalId: goalId,
      investmentItemId: investmentItemId,
      userId: userId,
      states: states,
      requestedCurrencyAmount: requestedCurrencyAmount
    }, CALL_API, {
      types: [GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_REQUEST, GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS, GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_FAILURE],
      endpoint: "operations/search/findByBroker_IdAndCurrency_IdAndGoal_IdAndInvestmentItem_IdAndUser_IdAndStateInAndRequestedCurrencyAmountLessThan".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST = "GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST";
export var GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS = "GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS";
export var GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE = "GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE";
export var RESET_GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN = "RESET_GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN";
export var loadOperationsByBrokerIdAndGoalIdAndUserIdAndStateIn = function loadOperationsByBrokerIdAndGoalIdAndUserIdAndStateIn(brokerId, goalId, userId, states) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      brokerId: brokerId,
      goalId: goalId,
      userId: userId,
      states: states
    });
    return dispatch(_defineProperty({
      brokerId: brokerId,
      goalId: goalId,
      userId: userId,
      states: states
    }, CALL_API, {
      types: [GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST, GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS, GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE],
      endpoint: "operations/search/findByBroker_IdAndGoal_IdAndUser_IdAndStateIn".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST = "GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST";
export var GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS = "GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS";
export var GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE = "GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE";
export var RESET_GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN = "RESET_GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN";
export var loadOperationsByCurrencyIdAndGoalIdAndUserIdAndStateIn = function loadOperationsByCurrencyIdAndGoalIdAndUserIdAndStateIn(currencyId, goalId, userId, states) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      sort: "createdAt,desc",
      projection: "withRequestedCurrencyAmountAndBrokerAndCurrencyAndInvestmentItem",
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      states: states
    });
    return dispatch(_defineProperty({
      currencyId: currencyId,
      goalId: goalId,
      userId: userId,
      states: states
    }, CALL_API, {
      types: [GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_REQUEST, GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_SUCCESS, GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIN_FAILURE],
      endpoint: "operations/search/findByCurrency_IdAndGoal_IdAndUser_IdAndStateIn".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_DEPOSITSBYUSERIDANDSTATE_REQUEST = "GET_DEPOSITSBYUSERIDANDSTATE_REQUEST";
export var GET_DEPOSITSBYUSERIDANDSTATE_SUCCESS = "GET_DEPOSITSBYUSERIDANDSTATE_SUCCESS";
export var GET_DEPOSITSBYUSERIDANDSTATE_FAILURE = "GET_DEPOSITSBYUSERIDANDSTATE_FAILURE";
export var RESET_GET_DEPOSITSBYUSERIDANDSTATE = "RESET_GET_DEPOSITSBYUSERIDANDSTATE";
export var fetchDepositsByUserIdAndState = function fetchDepositsByUserIdAndState(userId, state, maxId, sinceId, count) {
  var queryString = urlSearchParams({
    userId: userId,
    state: state,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  });
  return _defineProperty({
    userId: userId,
    state: state,
    maxId: maxId,
    sinceId: sinceId,
    count: count
  }, CALL_API, {
    types: [GET_DEPOSITSBYUSERIDANDSTATE_REQUEST, GET_DEPOSITSBYUSERIDANDSTATE_SUCCESS, GET_DEPOSITSBYUSERIDANDSTATE_FAILURE],
    endpoint: "operations/search/findDepositsByUser_IdAndState".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_REQUEST = "GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_REQUEST";
export var GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS = "GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS";
export var GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_FAILURE = "GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_FAILURE";
export var RESET_GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT = "RESET_GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT";
var fetchOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot = function fetchOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot(userId, states, maxId, sinceId, count, investmentItemIdFilterValue, typeFilterValue, boostFilterValue, goalIdFilterValue, brokerIdFilterValue, stateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  var queryString = urlSearchParams({
    userId: userId,
    states: states,
    maxId: maxId,
    sinceId: sinceId,
    count: count,
    investmentItemIdFilterValue: investmentItemIdFilterValue,
    typeFilterValue: typeFilterValue,
    boostFilterValue: boostFilterValue,
    goalIdFilterValue: goalIdFilterValue,
    brokerIdFilterValue: brokerIdFilterValue,
    stateFilterValue: stateFilterValue,
    startCreatedAtFilterValue: startCreatedAtFilterValue,
    endCreatedAtFilterValue: endCreatedAtFilterValue
  });
  return _defineProperty({
    userId: userId,
    states: states,
    maxId: maxId,
    sinceId: sinceId,
    count: count,
    investmentItemIdFilterValue: investmentItemIdFilterValue,
    typeFilterValue: typeFilterValue,
    boostFilterValue: boostFilterValue,
    goalIdFilterValue: goalIdFilterValue,
    brokerIdFilterValue: brokerIdFilterValue,
    stateFilterValue: stateFilterValue,
    startCreatedAtFilterValue: startCreatedAtFilterValue,
    endCreatedAtFilterValue: endCreatedAtFilterValue
  }, CALL_API, {
    types: [GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_REQUEST, GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS, GET_OPERATIONSBYUSERIDANDSTATEINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_FAILURE],
    endpoint: "operations/search/findByUser_IdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};
export var loadOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot = function loadOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot(userId, states, maxId, sinceId, count, investmentItemIdFilterValue, typeFilterValue, boostFilterValue, goalIdFilterValue, brokerIdFilterValue, stateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var key = [userId, states].join("-");
    var call = getState().apiCalls.loadOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot[key];
    if (call && call.isFetching) return null;
    return dispatch(fetchOperationsByUserIdAndStateInAndCashOpOfTransactionTypeBuyOrSellNot(userId, states, maxId, sinceId, count, investmentItemIdFilterValue, typeFilterValue, boostFilterValue, goalIdFilterValue, brokerIdFilterValue, stateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue));
  };
};