function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_HASHTAGSBYTEXTSTARTINGWITH_REQUEST = "GET_HASHTAGSBYTEXTSTARTINGWITH_REQUEST";
export var GET_HASHTAGSBYTEXTSTARTINGWITH_SUCCESS = "GET_HASHTAGSBYTEXTSTARTINGWITH_SUCCESS";
export var GET_HASHTAGSBYTEXTSTARTINGWITH_FAILURE = "GET_HASHTAGSBYTEXTSTARTINGWITH_FAILURE";
export var RESET_GET_HASHTAGSBYTEXTSTARTINGWITH = "RESET_GET_HASHTAGSBYTEXTSTARTINGWITH";

// Fetches hashtags by text containing value from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadHashtagsByTextStartingWith = function loadHashtagsByTextStartingWith(page, size, textStartingWith) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      textStartingWith: textStartingWith
    });
    return dispatch(_defineProperty({
      textStartingWith: textStartingWith
    }, CALL_API, {
      types: [GET_HASHTAGSBYTEXTSTARTINGWITH_REQUEST, GET_HASHTAGSBYTEXTSTARTINGWITH_SUCCESS, GET_HASHTAGSBYTEXTSTARTINGWITH_FAILURE],
      endpoint: "hashtags/search/findByTextStartingWith".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_REQUEST = "GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_REQUEST";
export var GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_SUCCESS = "GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_SUCCESS";
export var GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_FAILURE = "GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_FAILURE";
export var RESET_GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC = "RESET_GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC";

// Fetches all hashtags that have hashtags from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchHashtagsByOrderByMentionsCountDesc = function fetchHashtagsByOrderByMentionsCountDesc() {
  return _defineProperty({}, CALL_API, {
    types: [GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_REQUEST, GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_SUCCESS, GET_HASHTAGSBYORDERBYMENTIONSCOUNTDESC_FAILURE],
    endpoint: "hashtags/search/findByOrderByMentionsCountDesc",
    init: {
      credentials: "same-origin"
    }
  });
};