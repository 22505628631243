import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities = function brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byGoalIdAndUserIdAndInstant: {},
    byUserIdAndInstant: {},
    byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndMarketIdAndUserIdAndInstant: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_SUCCESS:
      var byGoalIdAndUserIdAndInstant = action.response.brokerCurrencyGoalInvestmentItemMarketUserBalancesByGoalIdAndUserIdAndInstant;
      return merge({}, state, {
        byGoalIdAndUserIdAndInstant: byGoalIdAndUserIdAndInstant
      });
    case ActionTypes.GET_WALLETVIEWBYUSERID_SUCCESS:
      var byUserIdAndInstant = action.response.brokerCurrencyGoalInvestmentItemMarketUserBalanceByUserIdAndInstant;
      return merge({}, state, {
        byUserIdAndInstant: byUserIdAndInstant
      });
    case ActionTypes.GET_CARTVIEWBYUSERIDANDOPERATIONSTATE_SUCCESS:
      var byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndMarketIdAndUserIdAndInstant = action.response.brokerCurrencyGoalInvestmentItemMarketUserBalanceByBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndMarketIdAndUserIdAndInstant;
      return merge({}, state, {
        byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndMarketIdAndUserIdAndInstant: byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndMarketIdAndUserIdAndInstant
      });
    default:
      return state;
  }
};
export default brokerCurrencyGoalInvestmentItemMarketUserBalanceEntities;